/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import Styles from './styles.module.scss';

import Logo from '../../../static/logo/white-colored-logo.svg';
import AppLoginModal from '../appLoginModal';

const headerStyle = {
  // background: 'linear-gradient(90.98deg, #0f3ac3 41.22%, #0575e6 122.67%)',
  margin: 0,
  backgroundColor: 'rgb(58, 66, 187)',
  // padding: '20px 0 20px 0px',
  height: 60
};

const hamburgerMenuStyle = {
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  right: 0,
  transition: '0.1s',
  display: 'flex',
  flexDirection: 'row'
};

const dblock = {
  display: 'block'
};

const dnone = {
  display: 'none'
};
const menuHeader = {
  display: 'flex',
  flexDirection: 'row',
  // backgroundColor: '#d8d8d8',
  backgroundColor: '#3a42bb',
  height: '64px',
  alignItems: 'center',
  justifyContent: 'space-between'
};

const menuOpen = {
  width: '60vw',
  background: '#fff'
};

const menuClose = {
  width: 'fit-content',
  marginLeft: 'auto',
  background: 'transparent',
  marginTop: '12px'
};

const Header = ({ isBlog }) => {
  const [selectedTab, setSelectedTab] = useState('');
  const [isScrolled, toggleScroll] = useState(false);
  const [isClicked, toggleClick] = useState(false);
  const [isCoBrandedPartnerPage, setCoBrandedPartnerPage] = useState(false);
  const [isAppCardModalOpen, toggleAppCardModal] = useState(false);

  const iconStyle = isClicked ? menuOpen : menuClose;

  useEffect(() => {
    if (window.location.href.indexOf('brands') > -1) {
      setSelectedTab('brands');
    }
    if (window.location.href.indexOf('patrons') > -1) {
      setSelectedTab('patrons');
    }
    if (window.location.href.indexOf('partners') > -1) {
      setSelectedTab('partners');
    }
    if (window.location.href.indexOf('about') > -1) {
      setSelectedTab('about');
    }
    if (window.location.href.includes('blog')) {
      setSelectedTab('blog');
    }
    if (window.location.href.indexOf('partners') > -1) {
      const { pathname } = window.location;
      const pathArr = pathname.split('/').filter(Boolean);
      if (pathArr.length >= 2) {
        setCoBrandedPartnerPage(true);
        setSelectedTab('branded-partners');
      }
    }
  }, []);

  const onClick = () => {
    toggleClick(!isClicked);
    let body = null;
    if (!isClicked) {
      body = document && document.body;
      // element = document && document.getElementById('layout') && document.getElementById('layout');
      // element.style.opacity = 0.8;
      // element.style.backgroundColor = '#000000';
      // element.style.zIndex = 100;
      // element.style.display = 'block';
      body.style.overflow = 'hidden';
    } else {
      body = document && document.body;
      // element = document && document.getElementById('layout') && document.getElementById('layout');
      // element.style.opacity = 0;
      // element.style.backgroundColor = '#ffffff';
      body.style.overflow = 'auto';
    }
  };

  const listenScrollEvent = () => {
    const ele = document.querySelector('.react-responsive-modal-overlay');
    if (window.scrollY > 50) {
      toggleScroll(true);
    } else if (!ele) {
      toggleScroll(false);
    }
  };

  useEffect(() => {
    listenScrollEvent();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  }, []);

  const handleAppCardModal = (val) => {
    toggleAppCardModal(val);
  };

  return (
    <header
      className={Styles.header}
      style={isBlog || isScrolled ? { ...headerStyle, top: 0 } : { top: 0 }}
    >
      <nav className={`${Styles.headerWrapper} container`}>
        <Link
          to="/"
          className={Styles.klubIconLink}
          style={{ opacity: isClicked ? 0.5 : 1 }}
        >
          <img src={Logo} alt="" />
        </Link>
        <a
          className={Styles.hiringTag}
          target="_blank"
          href="/careers"
          rel="noopener noreferrer"
        >
          We are hiring!
        </a>
        <div
          className={`${Styles.linkWrapper}`}
          style={isScrolled ? { top: 14 } : {}}
        >
          <Link
            to="/patrons"
            className={Styles.headerLink}
            style={
              selectedTab === 'patrons' ? { borderBottom: '1px solid' } : {}
            }
          >
            Patrons
          </Link>
          <Link
            to="/brands"
            className={Styles.headerLink}
            style={
              selectedTab === 'brands' ? { borderBottom: '1px solid' } : {}
            }
          >
            Brands
          </Link>
          <Link
            to="/partners"
            className={Styles.headerLink}
            style={
              selectedTab === 'partners' ? { borderBottom: '1px solid' } : {}
            }
          >
            Partners
          </Link>
          <Link
            to="/about"
            className={Styles.headerLinkAbout}
            style={selectedTab === 'about' ? { borderBottom: '1px solid' } : {}}
          >
            About
          </Link>
          <a
            target="_blank"
            href="/blog"
            className={`${Styles.headerLinkAbout} ${isCoBrandedPartnerPage ? 'mr-0' : ''}`}
            style={selectedTab === 'blog' ? { borderBottom: '1px solid' } : {}}
          >
            Blog
          </a>
          <button
            type="button"
            onClick={() => handleAppCardModal(true)}
            className={`btn btnklub2 ${Styles.headerLink} ${
              Styles.platformLink
            } ${isCoBrandedPartnerPage ? 'd-none' : ''}`}
          >
            Get Started
          </button>
          <div
            style={isClicked ? hamburgerMenuStyle : { right: '-100vw' }}
            className={Styles.mobileHeader}
          >
            <div
              style={{
                opacity: 0.4,
                width: '40vw',
                backgroundColor: '#000000'
              }}
              onClick={onClick}
            />
            <div
              className={Styles.hamburgerMenu}
              style={{ ...iconStyle }}
            >
              <div style={isClicked ? menuHeader : {}}>
                <div
                  className={Styles.menuText}
                  style={isClicked ? dblock : dnone}
                >
                  <Link to="/" className={Styles.klubIconLink}>
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <div
                  className={Styles.hamburgerWrapper}
                  onClick={() => onClick()}
                  style={isClicked ? { top: 'unset' } : { top: 20 }}
                >
                  <div
                    className={Styles.hamburger}
                    style={
                      isClicked
                        ? {
                          transform: 'rotate(45deg)',
                          position: 'absolute',
                          top: 0,
                          backgroundColor: '#ffffff'
                        }
                        : {}
                    }
                  />
                  <div
                    className={Styles.hamburger}
                    style={{
                      backgroundColor: isClicked ? 'transparent' : 'white'
                    }}
                  />
                  <div
                    className={Styles.hamburger}
                    style={
                      isClicked
                        ? {
                          transform: 'rotate(-45deg)',
                          position: 'absolute',
                          top: 0,
                          backgroundColor: '#ffffff'
                        }
                        : {}
                    }
                  />
                </div>
              </div>
              <div className={Styles.hamburgerMenuWrapper}>
                <Link
                  to="/patrons"
                  className={Styles.headerLink}
                  style={isClicked ? dblock : {}}
                  onClick={() => onClick()}
                >
                  Patrons
                </Link>
                <Link
                  to="/brands"
                  className={Styles.headerLink}
                  style={isClicked ? dblock : {}}
                  onClick={() => onClick()}
                >
                  Brands
                </Link>
                <Link
                  to="/partners"
                  className={Styles.headerLink}
                  style={isClicked ? dblock : {}}
                  onClick={() => onClick()}
                >
                  Partners
                </Link>
                <Link
                  to="/about"
                  className={Styles.headerLink}
                  style={isClicked ? dblock : {}}
                  onClick={() => onClick()}
                >
                  About
                </Link>
                <a
                  href="/blog"
                  target="_blank"
                  className={Styles.headerLink}
                  style={isClicked ? dblock : {}}
                  onClick={() => onClick()}
                >
                  Blog
                </a>
                {/* <button */}
                {/*  type="button" */}
                {/*  style={isClicked ? dblock : {}} */}
                {/*  onClick={handlePatronLinkClick} */}
                {/*  className={`btn btnklub3 ${Styles.headerLink} ${ */}
                {/*    Styles.platformLink */}
                {/*  } ${Styles.platformLinkMob} ${ */}
                {/*    isCoBrandedPartnerPage ? 'd-none' : '' */}
                {/*  }`} */}
                {/* > */}
                {/*  Patron Login */}
                {/* </button> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Modal open={isAppCardModalOpen} onClose={() => handleAppCardModal(false)} center={true}>
        <AppLoginModal />
      </Modal>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {
  isMobile: true
};

export default Header;
