import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Modal } from 'react-responsive-modal';
import styles from './styles.module.scss';

const Cookies = () => {
  const [isCookiesModalOpen, toggleCookiesModalOpen] = useState(false);

  const setDataIntoLocalStorage = () => {
    localStorage.setItem('isCookies', 1);
    toggleCookiesModalOpen(false);
  };

  const handleCloseDialog = () => {
    toggleCookiesModalOpen(false);
  };

  useEffect(() => {
    if (isCookiesModalOpen) {
      setTimeout(() => {
        document.body.style.overflowY = 'auto';
      }, 200);
    }
  }, [isCookiesModalOpen]);

  useEffect(() => {
    const isCookies = +localStorage.getItem('isCookies');
    if (isCookies === 0) {
      toggleCookiesModalOpen(true);
    }
  }, []);

  return (
    <Modal
      open={isCookiesModalOpen}
      onClose={() => toggleCookiesModalOpen(false)}
      showCloseIcon={false}
      focusTrapped={false}
      center={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScroll={true}
      animationDuration={500}
      classNames={{
        root: styles.customModalRootStyles,
        overlay: styles.customModalOverlayStyles,
        modal: styles.customModalStyles,
        closeButton: styles.customModalCloseButtonStyles,
        modalAnimationIn: styles.customEnterModalAnimation,
        modalAnimationOut: styles.customLeaveModalAnimation
      }}
    >
      <div className={`row container ${styles.cookiesContainer}`}>
        <div className="col-12">
          <div className={`row ${styles.cookiesTextButtonContainer}`}>
            <div className={`col-8 col-sm-12 ${styles.cookiesTextContainer}`}>
              Cookies help us deliver the best experience on our website. By
              using our website, you agree to the use of cookies. To find out
              more
              {' '}
              <span>
                <Link
                  className={styles.cookiesLinkTextContainer}
                  to="/cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  read our cookie policy.
                </Link>
              </span>
            </div>
            <div className={`col-4 col-sm-12 ${styles.cookiesButtonContainer}`}>
              <button
                type="button"
                onClick={setDataIntoLocalStorage}
                className={`btn btnklub3 ${styles.acceptbtn}`}
              >
                Accept
              </button>
              <button
                type="button"
                onClick={handleCloseDialog}
                className={`btn ${styles.declinebtn}`}
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Cookies;
