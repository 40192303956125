/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';

import Header from '../header';
import SaleHeader from '../header/saleHeader';
import Cookies from '../cookies';
import Footer from '../footer';
import './styles.module.scss';
import styles from './layout.module.scss';

const Layout = ({ children, isBlog, withCampaign, salesHeader }) => {
  const isMobile = typeof window !== 'undefined' && window.outerWidth <= 768;
  const isCookies = typeof window !== 'undefined' && window.location.pathname.includes('cookies');

  return (
    <>
      {/* <div id="layout" className={Styles.overlay} /> */}
      {withCampaign && (<SaleHeader isMobile={isMobile} salesHeader={salesHeader} />)}
      <Header siteTitle="klub" isBlog={isBlog} withCampaign={withCampaign} isMobile={true} />
      <main className={styles.mainContent}>{children}</main>
      {!isCookies && <Cookies />}
      <Footer />
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withCampaign: PropTypes.bool
};
Layout.defaultProps = {
  withCampaign: false
};

export default Layout;

// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         author
//       }
//     }
//   }
// `)
