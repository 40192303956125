import React from 'react';
import PatronAppImage from '../../images/misc/brand-app-card-image.svg';
import BrandAppImage from '../../images/misc/patron-app-card-image.svg';
import AppCard from './appCard';
import { openURL } from '../../utils';

const AppLoginModal = () => (
  <div className="app-login-modal-container">
    <div className="app-login__container">
      <AppCard
        image={PatronAppImage}
        title="Looking to invest?"
        subtitle="Invest in the brands you love & earn attractive
returns on your investments"
        btnText="Start Investing"
        onLinkClick={() => openURL('patron-app')}
        onBtnClick={() => openURL('patron-app_signup')}
      />
      <AppCard
        image={BrandAppImage}
        title="Finance your business"
        subtitle={
          'Get flexible growth capital without equity dilution\n'
          + 'and flexible repayments'
        }
        btnText="Apply now"
        onLinkClick={() => openURL('brand_app')}
        onBtnClick={() => openURL('brand_app_signup')}
      />
    </div>
  </div>
);

export default AppLoginModal;
