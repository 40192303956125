import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-responsive-modal';

import styles from './salesBanner.module.scss';
// import banner from '../../../static/brands/svg/sales-banner.svg';
import banner from '../../../static/brands/png/sale-banner.png';
import aceler from '../../../static/brands/svg/aceler8.png';
import { openURL } from '../../utils';

const customStyles = {
  root: { zIndex: 9999 }
};

const SalesBanner = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [exitType, setExitType] = useState('website_exit_popup');
  const isMobile = typeof window !== 'undefined' && window.outerWidth <= 768;

  const disableBanner = () => {
    window.onload = null;
    // DOM Events
    document.onmousemove = null;
    document.onkeydown = null;
  };

  const setPopupSession = () => {
    sessionStorage.setItem('salespopup', true);
  };

  useEffect(() => {
    (function () {
      let timer;
      window.onload = resetTimer;
      // DOM Events
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;

      function raiseBanner() {
        if (!isMobile) {
          if (!sessionStorage.getItem('salespopup')) {
            setOpen(true);
          }
          disableBanner();
          setExitType('website_idle_popup');
          setPopupSession();
        } else {
          disableBanner();
        }
      }

      function resetTimer() {
        clearTimeout(timer);
        timer = setTimeout(raiseBanner, 7000);
      }
    }());
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      showModal(type) {
        if (!isMobile) {
          if (type) setExitType(type);
          setOpen(true);
        }
      }
    })
  );

  return (
    <>
      <Modal
        open={open}
        center={true}
        onClose={() => {
          setOpen(false);
          setPopupSession();
        }}
        styles={customStyles}
        classNames={{
          modal: styles.customModal
        }}
      >
        <div className={`${styles.salesContainer}`}>
          <div className={`${styles.imagePart}`}>
            <img src={banner} alt="banner" />
          </div>
          <div className={`${styles.content}`}>
            <p className={styles.getFunds}>
              Get capital up to
              <br />
              ₹20 crores within 5 days
            </p>
            <p className={styles.offerText}>
              <span>Introducing</span>
              <img src={aceler} alt="aceler8" className={styles.acelerLogo} />
            </p>
            <p className={styles.headingText}>
              A Revenue Based Financing Fund
            </p>
            <ul className={styles.features}>
              <li>No equity dilution</li>
              <li>No pitch decks</li>
              <li>No EMIs</li>
            </ul>
            <div className={styles.popupAction}>
              <button
                type="button"
                className="btn btnklub3"
                onClick={() => openURL('brand_app_signup', `?utm_source=website&utm_medium=${exitType}&utm_campaign=FestiveSeason`)}
              >
                Get Funded
              </button>
              <button
                type="button"
                className={`btn ${styles.cancelCTA}`}
                onClick={() => {
                  disableBanner();
                  setOpen(false);
                  setPopupSession();
                }}
              >
                No thanks
              </button>
            </div>
            {/* <div className={styles.terms}>
              <p>
                T&C apply
              </p>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
});

export default SalesBanner;
