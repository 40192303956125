import PropTypes from 'prop-types';
import React from 'react';

import Styles from './styles.module.scss';
import Direction from '../../../../../static/brands/svg/Location marker.svg';

const Address = ({ heading, addressLine1, addressLine2, addressLine3, url }) => (
  <div className={Styles.addressWrapper}>
    <div className={Styles.countryName}>{heading}</div>
    <div className={Styles.address}>
      {addressLine1 && (<div className={Styles.subText}>{addressLine1}</div>)}
      <div className={Styles.subText}>{addressLine2}</div>
      <div className={Styles.subText}>{addressLine3}</div>
    </div>
    <div className={Styles.direction}>
      <img src={Direction} alt="" />
      <div className={Styles.map}>
        <a href={url} className={Styles.mapsUrlColor} target="_blank" rel="noopener noreferrer">GET DIRECTIONS</a>
      </div>
    </div>
  </div>
);

Address.propTypes = {
  heading: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  url: PropTypes.string
};

Address.defaultProps = {
  heading: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  url: ''
};

export default Address;
