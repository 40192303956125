import React from 'react';
import { Link } from 'gatsby';
import Address from './components';
import Styles from './styles.module.scss';
import '../../common-styles/styles.css';

import Logo from '../../../static/logo/klub-footer-logo.svg';
import LinkedIn from '../../../static/footer/svg/linked-in.svg';
import Twitter from '../../../static/footer/svg/twitter.svg';
import Instagram from '../../../static/footer/svg/instagram.svg';
import FaceBook from '../../../static/footer/svg/facebook.svg';
import Mail from '../../../static/footer/svg/mail.svg';
// import Medium from '../../../static/footer/svg/medium.svg';

const socialMedias = [
  {
    name: 'LinkedIn',
    img: LinkedIn,
    link: 'https://www.linkedin.com/company/klubworks/'
  },
  {
    name: 'Twitter',
    img: Twitter,
    link: 'https://twitter.com/klubworks'
  },
  {
    name: 'Instagram',
    img: Instagram,
    link: 'https://www.instagram.com/klubworks/'
  },
  {
    name: 'FaceBook',
    img: FaceBook,
    link: 'https://www.facebook.com/klubworks/'
  },
  {
    name: 'Mail',
    img: Mail,
    link: 'mailto:contact@klubworks.com'
  }
  // {
  //   name: 'Medium',
  //   img: Medium,
  //   link: 'https://medium.com/klubworks'
  // }
];

const links = [
  {
    heading: 'Home',
    link: '/'
  },
  {
    heading: 'Brands',
    link: '/brands'
  },
  {
    heading: 'Patrons',
    link: '/patrons'
  },
  {
    heading: 'Partners',
    link: '/partners'
  },
  {
    heading: 'About',
    link: '/about'
  }
];

// {
//   heading: 'Careers',
//   link: 'https://klubworks.freshteam.com/jobs/'
// }

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer>
    <div className={Styles.footer}>
      <div className="container">
        <div className={Styles.displayDesktop}>
          <div className={Styles.topContent}>
            <div className={Styles.companyDetails}>
              <img src={Logo} alt="" className={Styles.footerLogo} />
              <div className={Styles.content}>
                <div className={Styles.subText}>
                  <Link to="/terms-of-use" className={Styles.footerLink}>
                    Terms of Use
                  </Link>
                </div>
                <div className={Styles.subText}>
                  <Link to="/privacy-policy" className={Styles.footerLink}>
                    Privacy Policy
                  </Link>
                </div>
                <div className={Styles.subText}>
                  <Link to="/risks" className={Styles.footerLink}>
                    Risks
                  </Link>
                </div>
              </div>
            </div>
            <Address
              heading="India"
              addressLine1=""
              addressLine2="L376 / A, Obeya Verve, VM Arcade, 3rd Floor,"
              addressLine3="14th B Cross Rd, Sector 6, HSR Layout, Karnataka 560102"
              url="https://goo.gl/maps/hd7xE2aMJ14DcpGY8"
            />
            <Address
              heading="Singapore"
              addressLine1="160, Robinson Road, #20-03,"
              addressLine2="Singapore - 068914"
              addressLine3=""
              url="https://www.google.com/maps?daddr=160,+Robinson+Road,%2320-03,Singapore+-+068914"
            />
            <div className={Styles.socialMediaLinks}>
              <div />
              {socialMedias.map((item) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={Styles.icon}
                  key={item.name}
                >
                  <img src={item.img} alt="" />
                </a>
              ))}
            </div>
          </div>
          <div className={Styles.line} />
          <div className={Styles.links}>
            <div className={Styles.linksWidth}>
              ©
              {' '}
              {currentYear}
              , Ken Capital Technologies Pte Ltd
            </div>
            <div className={Styles.linkWrapper}>
              {links.map((item) => (
                <Link
                  to={item.link}
                  className={Styles.headerLink}
                  target={item.heading === 'Careers' ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  key={item.heading}
                >
                  {item.heading}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className={Styles.displayMobile}>
          <img src={Logo} alt="" className={Styles.footerLogo} />
          <div className={Styles.footerMobSection2}>
            <div className={Styles.socialMediaLinks}>
              {socialMedias.map((item) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={Styles.icon}
                  key={item.name}
                >
                  <img src={item.img} alt="" />
                </a>
              ))}
            </div>
            <div className={Styles.addressContainer}>
              <Address
                heading="India"
                addressLine1=""
                addressLine2="L376 / A, Obeya Verve, VM Arcade, 3rd Floor,"
                addressLine3="14th B Cross Rd, Sector 6, HSR Layout, Karnataka 560102"
                url="https://goo.gl/maps/hd7xE2aMJ14DcpGY8"
              />
              <Address
                heading="Singapore"
                addressLine1="160, Robinson Road,"
                addressLine2="#20-03,"
                addressLine3="Singapore - 068914"
                url="https://www.google.com/maps?daddr=160,+Robinson+Road,%2320-03,Singapore+-+068914"
              />
            </div>
          </div>
          <div className={Styles.line} />
          <div className={Styles.linkWrapper}>
            {links.map((item) => (
              <Link
                to={item.link}
                className={Styles.headerLink}
                target={item.heading === 'Careers' ? '_blank' : '_self'}
                rel="noopener noreferrer"
                key={`mobile-${item.heading}`}
              >
                {item.heading}
              </Link>
            ))}
          </div>
          <div className={Styles.content}>
            <Link to="/terms-of-use" className={Styles.footerLink}>
              Terms of Use
            </Link>
            <Link to="/privacy-policy" className={Styles.footerLink}>
              Privacy Policy
            </Link>
            <Link to="/risks" className={Styles.footerLink}>
              Risks
            </Link>
          </div>
          <div className={Styles.line} />
          <div className={Styles.bottomText}>
            {`© ${currentYear}, Ken Capital Technologies Pte Ltd`}
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {
  siteTitle: ``
};

export default Footer;
