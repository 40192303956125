/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import SalesBanner from '../banner/salesBanner';

import styles from './styles.module.scss';
import { openURL } from '../../utils';

const SaleHeader = ({ salesHeader }) => {
  const childRef = useRef();

  const triggerModal = () => childRef.current?.showModal('website_exit_popup');

  useEffect(() => {
    // trigger modal when user is moving out of the tab.
    document.addEventListener('mouseleave', (event) => {
      if (!sessionStorage.getItem('salespopup') && (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth))) {
        setTimeout(() => {
          triggerModal();
        }, 200);
      }
    });
  }, []);

  return (
    <>
      {salesHeader && (
      <div className={styles.saleWrapper}>
        <div className="container">
          <div className={styles.saleHeader}>
            <p className={styles.bannerText}>
              This festive season ramp up your inventory with financing from Klub starting at 5%
            </p>
            <p className={styles.avail}>
              <a
                className={styles.bannerText}
                onClick={() => openURL('brand_app_signup', `?utm_source=website&utm_medium=header_cta&utm_campaign=FestiveSeason`)}
              >
                Get Capital!
              </a>
            </p>
          </div>
        </div>
      </div>
      )}
      <SalesBanner ref={childRef} />
    </>
  );
};

SaleHeader.propTypes = {
  salesHeader: PropTypes.bool
};

SaleHeader.defaultProps = {
  salesHeader: false
};

export default SaleHeader;
