/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */
import React from 'react';

import './styles.scss';

const AppCard = (props) => {
  const { title, image, btnText, subtitle, onLinkClick, onBtnClick } = props;
  return (
    <div className="app-card__container">
      <div className="app-card__image">
        <img src={image} alt="brand" />
      </div>
      <div className="app-card__title">
        <h4>{title}</h4>
      </div>
      <div className="app-card__subtitle">
        <p>{subtitle}</p>
      </div>
      <div className="app-card__button">
        <button type="button" className="btn btnklub3" onClick={onBtnClick}>
          {btnText}
        </button>
      </div>
      <div className="app-card__link">
        <p>Already on Klub?</p>
        {' '}
        <a onClick={onLinkClick}>Log In</a>
      </div>
    </div>
  );
};

export default AppCard;
